import React from "react";
import "./Services.css";
const projects = [
  {
    img: "/img/jkslogo.jpg",
  },
  {
    img: "/img/gstc.jpg",
  },
  {
    img: "/img/navkar.jpg",
  },
  {
    img: "/img/Ashirwad.jpg",
  },
  {
    img: "/img/hingalaj.jpg",
  },
  {
    img: "/img/jainsales.jpg",
  },
];

function Services() {
  const handleClick = (url) => {
    window.open("https://www.youtube.com/", "_blank");
  };

  return (
    <>
      <div className="whyUs">
        <h1>Our Service's</h1>
        <h5>
          We offer Customized Solutions for integrating your various processes
          under one platform. All needs of firms in respect of accounting, Human
          Resources, Taxation, MIS reports and any other specific requirements
          are fulfilled by our software in which duplicate data entry is
          avoided. You can also have maker / checker arrangement. In effect, you
          can grow your business without increasing your staff. In nutshell,
          this is customize ERP without unnecessary data entry and controls of
          standard ERP. Migration from your older platform to our platform is
          also very smooth as we use to aid customers in migrating data to our
          software. This eliminates the need of relaunching of old master data
          and consequential headache. Your various branches can be seamlessly
          integrated using our web application. You can also operate this
          software from anywhere in the world.
        </h5>

        <div className="projectCart">
          <h1>Our Client's</h1>
          <div className="projects row d-flex justify-content-around">
            {projects.map((project) => {
              return (
                <div className="SingleProjectWrap col-sm-6 col-xl-4 col-12">
                  <div className=" singleProject  position-relative">
                    {/* <div style={{  margin: '20px',  backgroundColor: 'rgb(242,251,247)'}}> */}
                    <div className="projectHeading">{project.ProjectName}</div>
                    <div className="imgDiv d-flex justify-content-center">
                      <img src={project.img} alt="" />
                    </div>
                    <h5>{project.subHeading}</h5>
                    <p>{project.description}</p>
                    {/* <div className="technologies row d-flex justify-content-around">
                  {
                    project.technologies.map((tech)=>{
                      return(
                        <p className='col-md-5'>{tech}</p> 
                        )
                      })
                    }
                </div> */}
                    
                  </div>
                </div>
              );
            })}
          </div>
        </div>

       
      </div>
    </>
  );
}

export default Services;

