import React from 'react'
import "./Testimonials.css"


const projects = [
  {
    ProjectName : "JK Group Kolhapur",
    img : "/img/jk.jpg",
    subHeading : "Uppal Shah",
    description : "Since 1994 we have been provided software development by Lata Software Consultancy, with their intact professionalism, prompt response, courteous service. The constant upgradations with new technologies has always brought our work at great ease. We are glad to have been collaborated with this dynamic team of Mr.Pankaj Dixit! Wishing you more and more success.",
  
  },
  {
    ProjectName : "Navkar Traders",
    img : "/img/navkari.jpg",
    subHeading : "Amit Jain",
    description : "The Sugar software provided to us by M/s Lata Software Consultancy is as per our requirements. They are also updating / making modifications in the software as per our suggestions. The post installation service provided by them excellent. We are very much happy with their service to us. We expect to continue good relations with them in future also.",
   
  },
  {
    ProjectName : "Gautam Sugar Trading Company",
    img : "/img/Gautam.jpg",
    subHeading : "Vinit Shah",
    description : "We are using the ERP software of M/s Lata Software Consultancy since many years. The software is very much comprehensive; which has made our office paperless. We are using this software in our two offices (One at Kolhapur and other at Pune). Since their software is web based; it gives effect as if we are working at one place. We are extremely happy with their service.",
  
  }


]


function Project() {
  return (
    <div className='projectDivs'>
      <h1>Our Client's</h1>
      <div className="projects row d-flex justify-content-around">

      {
        projects.map((project)=>{
          return(
            <div className='SingleProjectWrap col-sm-6 col-xl-4 col-12'>

            <div className=" singleProject  position-relative">
              {/* <div style={{  margin: '20px',  backgroundColor: 'rgb(242,251,247)'}}> */}
                <div className='projectHeading'>
                  {project.ProjectName}
                </div>
                <div className="imgDiv d-flex justify-content-center">
                  <img src={project.img} alt="" />
                </div>
                <h5>{project.subHeading}</h5>
                <p>{project.description}</p>
                {/* <div className="technologies row d-flex justify-content-around">
                  {
                    project.technologies.map((tech)=>{
                      return(
                        <p className='col-md-5'>{tech}</p> 
                        )
                      })
                    }
                </div> */}
              {/* </div> */}
            </div>
            </div>
          )
        })
      }



        
      </div>
    </div>
  )
}

export default Project
